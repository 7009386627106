import queryString from 'query-string';
import { NextPage, GetServerSideProps } from 'next';

import {
  getUICartEntries,
  getUIProductSelectionPageEntries,
  getCatalogEntriesByCountryAndChannel,
  getMarketEntriesByCountryCode,
  getCountriesWithLocales,
  getUINotificationsEntries,
  getUIProductDetailsPageEntries,
  getUICreateOrderPageEntries,
  getFooterEntries,
  getAvailableChannelsByCountry,
  getExperimentalTaxNoticesEntries,
} from 'shared/infra/contentful';
import { ProductSelection } from 'productSelection/pages/ProductSelection';
import { getCountryCodeFromISOLocale } from 'shared/i18n/helpers';
import {
  IProductSelectionServerSidePropsContext,
  IServerSidePropsContext,
} from 'shared/types/static-props';
import { ShopExperience } from 'shared/constants/ShopExperience';
import { setGlobalCatalogFromServerSide } from 'shared/services/OrderInformationService.globals';
import { getEnabledShopCountriesWithLocales } from 'productSelection/services/country';
import { isValidShopExperience } from 'shared/services/ShopExperience';
import {
  getProductCatalog,
  parseContentfulDigitalProducts,
  parseContentfulProducts,
} from 'shared/services/products';
import { IProductSelectionProps } from 'productSelection/pages/ProductSelection/ProductSelection';
import {
  CORE_EU_CTA_TO_SSO,
  VARIATION,
  ODL_EXPERIMENT,
} from 'shared/services/optimizely/constants';
import { previewInfo } from 'shared/infra/contentful/preview';
import { convertImageToPng } from 'shared/services/imageUrl';
import { IAppProps } from 'pages/_app';
import { setStaleWhileRevalidateCacheControl } from 'shared/infra/cacheControl';
import {
  getGalleryPricesByContentfulId,
  getPricesByMarket,
} from 'shared/infra/storefront/markets/prices';

const Page: NextPage<IProductSelectionProps & IAppProps> = (props) => (
  <ProductSelection {...props} />
);

export const generateProductSelectionPageServerSideProps: GetServerSideProps =
  async (context: IProductSelectionServerSidePropsContext) => {
    const { locale, params, res, query, preview } = context;

    const countryCode = getCountryCodeFromISOLocale(locale);

    // Temporary hard coding for rollout to BR only, as this flow
    // will need to be tested in core EU before a full rollout
    // see experiments/cta-to-checkout/README.md
    const isCtaToCheckoutEnabled =
      countryCode === 'BR' || query[CORE_EU_CTA_TO_SSO.id] === VARIATION;

    previewInfo().setPreview(preview);

    const shopExperience = params?.source || ShopExperience.SIGNUP;

    const isBusinessAccountBundleEnabled =
      countryCode === 'GB' && shopExperience === ShopExperience.SIGNUP;

    const isCtaToContextualizedSignupEnabled =
      query[CORE_EU_CTA_TO_SSO.id] === VARIATION;

    const isODLExperimentEnabled = query[ODL_EXPERIMENT.id] === VARIATION;

    // Support '?product' parameter as a fallback for '?products'
    let productsOnQuery = [];
    const productsQueryParam = query.products || query.product;
    if (Array.isArray(productsQueryParam)) {
      productsOnQuery = productsQueryParam;
    } else {
      productsOnQuery = productsQueryParam?.split(',');
    }

    const countryAvailableChannels = await getAvailableChannelsByCountry(
      countryCode,
    );

    /**
     * Prevents status 500 pages to be displayed for users
     * accessing the shop with an invalid shop channel.
     */
    if (!isValidShopExperience(shopExperience, countryAvailableChannels)) {
      return {
        notFound: true,
      };
    }

    setStaleWhileRevalidateCacheControl(res);

    const cartContentEntries = await getUICartEntries(locale);
    const pageContentEntries = await getUIProductSelectionPageEntries(locale);
    const orderOverviewEntries = await getUICreateOrderPageEntries(locale);

    const orderOverviewContent = orderOverviewEntries.items[0]?.fields;

    // TODO: review this after experiment is complete:
    // * add this to productSelection content types in case of success
    // * remove it
    const productDetailsContentEntries = await getUIProductDetailsPageEntries(
      locale,
    );
    const notificationsContentEntries = await getUINotificationsEntries(locale);
    const marketEntries = await getMarketEntriesByCountryCode(countryCode);
    const marketReference = marketEntries.items[0]?.fields.marketReference;
    const catalogEntries = await getCatalogEntriesByCountryAndChannel({
      countryCode,
      channel: shopExperience,
      locale,
    });
    const digitalProductsEntries =
      catalogEntries.items[0]?.fields.digitalProducts || [];
    const footerContentEntries = await getFooterEntries(locale);

    /**
     * The list of countries is used to display country and language dropdown
     * in the bottom of the page
     */
    const countriesWithLocalesEntries = await getCountriesWithLocales(locale);

    setGlobalCatalogFromServerSide(shopExperience);
    const notificationsContent = notificationsContentEntries.items[0]?.fields;
    const cartContent = cartContentEntries.items[0]?.fields;
    const pageContent = pageContentEntries.items[0]?.fields;

    const sections = pageContent.sections || null;

    const metaReference =
      pageContent.metaReference?.fields.productSelectionPage?.fields;
    const footerContent = footerContentEntries.items[0]?.fields;
    const productDetailsContent = productDetailsContentEntries.items[0]?.fields;

    // TODO: after various product selection experiments have concluded,
    // understand which pieces of content are needed (ex, icon is not in all exp
    // designs) and map only those to pass as props.
    const transparentPricingIconUrl =
      pageContent.transparentPricingComponent?.fields?.icon?.fields?.file
        ?.url || '';
    const transparentPricingContent = {
      fields: {
        ...pageContent.transparentPricingComponent?.fields,
        icon: {
          fields: {
            ...pageContent.transparentPricingComponent?.fields?.icon?.fields,
            file: {
              ...pageContent.transparentPricingComponent?.fields?.icon?.fields
                ?.file,
              url: convertImageToPng(transparentPricingIconUrl),
            },
          },
        },
      },
    };

    const parsedDigitalProducts = parseContentfulDigitalProducts(
      digitalProductsEntries,
    );
    const availableProducts = getProductCatalog(
      catalogEntries.items[0]?.fields.products,
      productsOnQuery,
    );

    /**
     * Since we'd like to show more product information when a single
     * product is available on the shop, we need to redirect users from
     * the product selection page to the product details page.
     */
    if (availableProducts.length === 1) {
      const product = availableProducts[0];

      // Remove products/product param from query to avoid redirect loops.
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { products, product: productParam, ...queryParams } = query;
      const parsedQuery = queryString.stringify(queryParams);

      // Omit /signup from signup shop redirects
      const channelRedirectRoute =
        shopExperience !== ShopExperience.SIGNUP ? `${shopExperience}/` : '';

      return {
        redirect: {
          destination: [
            `/${locale}/${channelRedirectRoute}product-selection/${product.fields.trackingId}`,
            parsedQuery ? `?${parsedQuery}` : '',
          ].join(''),
          permanent: false,
        },
      };
    }

    const parsedProducts = parseContentfulProducts(availableProducts);
    const pricesByMarket = await getPricesByMarket(marketReference, query?.prc);
    const prices = getGalleryPricesByContentfulId(
      parsedProducts,
      pricesByMarket,
    );

    const taxNoticeMessage = (await getExperimentalTaxNoticesEntries(locale))
      .items[0]?.fields || {
      vatValueIncludedMessage: '',
    };

    return {
      props: {
        products: parsedProducts,
        digitalProducts: parsedDigitalProducts,
        prices,
        marketReference: marketEntries.items[0]?.fields.marketReference,
        cartContent,
        pageContent: {
          ...pageContent,
          productBuyNowText: productDetailsContent.productBuyNowText,
          addToCartText: productDetailsContent.productCallToActionText,
          transparentPricingComponent: {
            ...transparentPricingContent,
          },
        },
        notificationsContent,
        commerceLayerClient: process.env.NEXT_PUBLIC_COMMERCE_LAYER_CLIENT_ID,
        countries: getEnabledShopCountriesWithLocales(
          countriesWithLocalesEntries.items,
        ),
        metaReference,
        footerContent,
        isDiscountPriceEnabled:
          !!marketEntries.items[0]?.fields.enableDiscountPrice,
        isCtaToCheckoutEnabled,
        isCtaToContextualizedSignupEnabled,
        orderOverviewTitle: orderOverviewContent.iframeOverviewTitle,
        taxMessage: taxNoticeMessage.vatValueIncludedMessage,
        isBusinessAccountBundleEnabled,
        sections,
        isODLExperimentEnabled,
        defaultTaxRate: pricesByMarket.data.attributes.tax_rate,
      },
    };
  };

export const getServerSideProps: GetServerSideProps = async (
  context: IServerSidePropsContext,
) => generateProductSelectionPageServerSideProps(context);

export default Page;
